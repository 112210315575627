<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-shops' }">{{
            $t("MENU.ITEM.SHOPS.SHOPS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingShop"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <ShopForm ref="Form" :form="form"></ShopForm>
          <v-overlay :value="isLoadingShop">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingShop"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_SHOP } from "@/modules/shop/store/shop.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";

import ShopForm from "../components/ShopForm.vue";

export default {
  components: {
    KTCard,
    SaveButtonDropdown,
    ShopForm
  },
  data() {
    return {
      errors: {},
      shop: {},
      form: {
        name: null,
        url: null,
        categories: [],
        description: null,
        image: null,
        isNew: false,
        active: true
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingShop"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Магазини", route: { name: "list-shops" } },
      { title: "Добавяне на магазин" }
    ]);
  },
  methods: {
    prepareFormData() {
      const { image, ...rest } = this.form;
      let formData = new FormData();
      formData.append("image", image);
      formData.append("payload", JSON.stringify(rest));
      return formData;
    },
    onSave(next) {
      this.$refs.Form.$v.form.$touch();
      if (this.$refs.Form.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      console.log(this.form.categories)
      this.$store
        .dispatch(SAVE_SHOP, this.prepareFormData())
        .then(data => {
          this.errors = data.data.errors;
          let itemEdit = data.data;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-shop",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-shop" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-shops" });
          }
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    }
  }
};
</script>
